import { JumbotronListResponse, SitecoreChildrenResponse } from '@maverick/entity';
import { GraphQLRequestClient } from '@sitecore-jss/sitecore-jss-nextjs/graphql';
import { getJumbotronList } from './graphql/Queries/Jumbotron.graphql';

export const GetJumbotronsList = async (dataSource: string): Promise<JumbotronListResponse> => {
	const result = await Request(dataSource, getJumbotronList);

	return result.item.children;
};

const Request = async (dataSource: string, gql: string): Promise<SitecoreChildrenResponse<JumbotronListResponse>> => {
	const gqlClient = new GraphQLRequestClient(String(process.env.GRAPH_QL_ENDPOINT), {
		apiKey: process.env.SITECORE_API_KEY,
	});

	return await gqlClient.request<SitecoreChildrenResponse<JumbotronListResponse>>(gql, {
		datasource: dataSource,
	});
};
