import { RichText, Text, Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { Accordion, AccordionColumns, AccordionProps, AccordionVariant } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';

type AccordionListRenderingProps = ComponentProps & {
	rendering: {
		fields: {
			Title: Field<string>;
			Description: Field<string>;
			AccordionItem: [{ name: string; fields: { Title: { value: string }; Description: Field<string> } }];
			Variant: { value: string };
			ComponentId: { value: string };
			Columns: { value: AccordionColumns };
		};
		params: {
			Variant: string;
		};
	};
};

const AccordionListRendering = (props: AccordionListRenderingProps): JSX.Element => {
	const { Title, Description, AccordionItem, ComponentId, Columns } = props?.rendering?.fields;

	const isExperienceEditorPage = isEditorActive();

	const mappedItems = AccordionItem.map((item) => {
		const { Title, Description } = item.fields;
		return {
			title: <Text field={Title} />,
			id: Title.value,
			text: <RichText field={Description} />,
			initOpen: isExperienceEditorPage,
		};
	});

	const handleVariant = (): AccordionVariant => {
		const sitecoreVariant = props?.rendering?.params?.Variant;
		const variant = sitecoreVariant
			? (sitecoreVariant?.toLocaleLowerCase() as AccordionVariant)
			: AccordionVariant.Primary;
		return variant;
	};

	const AccordionProps: AccordionProps = {
		title: isExperienceEditorPage || Title.value ? <Text field={Title} /> : undefined,
		subtitle: isExperienceEditorPage || Description.value ? <Text field={Description} /> : undefined,
		items: mappedItems,
		variant: handleVariant(),
		componentId: ComponentId.value,
		columns: Columns?.value ? Columns?.value : AccordionColumns.TWO,
	};

	return <Accordion {...AccordionProps} />;
};

export default withDatasourceCheck()<AccordionListRenderingProps>(AccordionListRendering);
